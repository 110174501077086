<template>
  <div class="kecheng">
    <kind-choose-view :show-kc="true" :type="117" @isOk="isOk" />

    <!-- 最近直播  内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
        <!-- 直播时间 -->
        <!-- 活动详情 -->
        <img class="item-img" :src="item.img">
        <div class="detail" :class="item.productLine == 2 ? 'boutiqueBan' : ''">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>
          <div class="flex-c m-t-10" :class="item.productLine == 2 ? 'boutiqueTxt' : ''">
            <div :class="item.productLine == 2 ? 'mr-5' : 'item-tips elp mr-5'">
              {{ dealType(item.types) }}
            </div>
            <div :class="item.productLine == 2 ? 'mr-5' : 'item-tips elp mr-5'">
              {{ Math.floor(item.allTime) }}课时
            </div>
          </div>

          <!-- 价格 -->
          <div class="flex-between-c price-box">
            <div class="price" :class="item.productLine == 2 ? 'boutiquePrice' : ''">
              <span class="price-icon">￥</span>{{ item.price }}
            </div>
            <div class="tips">
              <img class="img" src="@/assets/img/Home/Frame.png" object-fit="contain">
              人气值 {{ item.clicks ? item.clicks : 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div style="margin: 0 auto;" v-if="!list || list.length == 0">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <!-- <div v-if="!list || list.length == 0" class="nothing">
      <div class="nothing-text">暂无内容哦~</div>
    </div> -->
    <div class="flex-jc-c m-t-20">
      <el-pagination
        :total="total"
        :current-page="pageNum"
        :page-sizes="[12, 20, 28, 40]"
        :page-size="pageSize"
        :pager-count="5"
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewjpkc.vue'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      pageSize: 12,
      pageNum: 1,
      loading: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    // 调用轮播图导航接口方法
    this.getList()
  },
  methods: {
    dealType(type) {
      let temp = null
      switch (type) {
        case 1:
          temp = '直播'
          break
        case 2:
          temp = '录播'
          break
        case 3:
          temp = '音频'
          break
        case 4:
          temp = '图文'
          break
        case 5:
          temp = '面授'
          break
        default:
          ''
      }
      return temp
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: '/zhiboclassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: '/videoxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: '/audioCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: '/imgCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: '/faceCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      await know
        .getCourseList(
          this.pageSize,
          this.pageNum,
          this.search.types,
          this.search.school ? this.search.school : undefined,
          this.search.kind ? this.search.kind : undefined,
          this.search.zyId ? this.search.zyId : undefined,
          this.search.kcId ? this.search.kcId : undefined,
          this.search.productLine == 4 ? null : this.search.productLine,
          this.search.areaId ? this.search.areaId : undefined
        )
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    searchClick() {
      this.pageSize = 12
      this.pageNum = 1
      this.total = 0
      this.list = []
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.searchClick()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)

      /*     router.push({
        name: "直播课详情",
        params: {
          id: item.id,
          type: 2,
        },
      }); */
    }
  }
}
</script>
<style lang="less" scoped>
.kecheng {
  padding-bottom: 24px;
}

// 最近直播  内容
.live {
  // width: 66.2%;
  width: 1244px;
  margin-left: calc(50% - 610px);
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 40px;

  .item {
    margin-bottom: 24px;
    width: 293px;
    // height: 341px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 24px;

    &:nth-child(4n) {
      margin-right: 0px !important;
    }

    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px 1px rgba(153, 153, 153, 0.20000000298023224);
    }

    .item-img {
      width: 293px;
      height: 164.8125px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      object-fit: cover;
    }

    .boutiqueBan {
      background-image: url('../../assets/img/Home/boutiqueBan.png');
      height: 150px;
      background-size: 100% 100%;

      .detail-title {
        color: #5a4629 !important;
      }

      .tips {
        color: #5a4629 !important;
      }
    }

    .detail {
      padding: 12px 16px;

      .boutiqueTxt {
        width: 255px;
        height: 26px;
        padding: 0px 8px;
        line-height: 26px;
        font-size: 12px;
        background: none;
        background-image: url('../../assets/img/Home/boutiqueCoure.png');
        background-size: 100% 100%;
        color: #a57800;

        // padding-left: 83px;
        .mr-5 {
          margin-right: 5px;
        }
      }

      .detail-title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        height: 48px;
      }

      .item-tips {
        height: 20px;
        background: #fff2e9;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        // border: 1px solid #ff7b1c;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ff7b1c;
        line-height: 18px;
        padding: 0px 8px;
      }

      .item-tips+.item-tips {
        margin-left: 8px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;

        .img {
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }

      .price-box {
        margin-top: 18px;

        .boutiquePrice {
          color: #5a4629 !important;
        }

        .price {
          font-family: Microsoft YaHei-Regular;
          color: #ff5d51;
          font-size: 18px;
          line-height: 18px;
          font-weight: Bold;

          .price-icon {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}</style>
